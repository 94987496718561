import React, { useEffect, useState } from 'react';
import * as language from '../constants/languages';
import Trash from '../assets/svg/delete.svg';
import { useNavigate } from "react-router-dom";
import TabHome from '../assets/svg/bottom tab bar/tabhome.svg';
import TabCategory from '../assets/svg/bottom tab bar/tabcategory.svg';
import SpinnerLoader from '../components/SpinnerLoader';
import '../styles/CartComponent.css';

export default function CartComponent({ invalidItems, continueOrder, hideModal }) {
    const branchId = localStorage.getItem('storeId');
    const navigation = useNavigate();
    const [decimalPlaces, setDecimalPlaces] = useState(2);
    const [outOfStock, setOutOfStock] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState();
    const [invalidItemsInCart, setInvalidItemsInCart] = useState();

    useEffect(() => {
        getCompanyInfo();
        getItems();
    }, []);

    function getItems() {
        setInvalidItemsInCart(invalidItems);
    }

    async function getCompanyInfo() {
        const value = localStorage.getItem('companyInfo');
        if (value === null || value === undefined) {
            setCurrencySymbol("");
        } else {
            const companyInfo = JSON.parse(value);
            setCurrencySymbol(companyInfo.currencySymbol);
            setDecimalPlaces(companyInfo.decimalValue);
        }
    }

    async function removeItem(id, cartItem) {
        const cart = localStorage.getItem('cartItems');
        const cartitems = JSON.parse(cart);
        const elementsIndex = invalidItemsInCart?.findIndex(element => element.product.productId === cartItem.product.productId);

        if (elementsIndex !== undefined && elementsIndex !== -1) {
            cartitems.customerCartItems.splice(elementsIndex, 1);
            const updatedInvalidItems = [...invalidItemsInCart];
            updatedInvalidItems.splice(elementsIndex, 1);
            setInvalidItemsInCart(updatedInvalidItems);

            if (cartitems?.customerCartItems?.length === 0) {
                localStorage.removeItem('cartItems');
            } else {
                localStorage.setItem('cartItems', JSON.stringify(cartitems));
            }
            const event = new CustomEvent('productQtyChange');
            window.dispatchEvent(event);
            showToastMessage(language.removedFromCart);
        }
    }

    const showToastMessage = (message) => {
        this.setState({
            message: message,
            snackopen: true
        })
    }

    function toggleAndNavigate(screenName) {
        const event = new CustomEvent('catalogueShare');
        window.dispatchEvent(event)
        navigation(screenName);
    }

    async function removeAllInvalidItems() {
        setInvalidItemsInCart(undefined);

        const cart = localStorage.getItem('cartItems');
        const cartitems = JSON.parse(cart);

        invalidItemsInCart?.forEach(cartItem => {
            const elementsIndex = cartitems.customerCartItems.findIndex(item => item.product.productId === cartItem.product.productId);
            if (elementsIndex !== -1) {
                cartitems.customerCartItems.splice(elementsIndex, 1);
            }
        });

        if (cartitems.customerCartItems.length === 0) {
            localStorage.removeItem('cartItems');
            const event = new CustomEvent('productQtyChange');
            window.dispatchEvent(event);

            hideModal();
            navigation(-1);
        } else {
            localStorage.setItem('cartItems', JSON.stringify(cartitems));
            hideModal();
            continueOrder();
        }

        showToastMessage(language.removedFromCart);
    }

    return (
        <div className="cart-container">
            {
                invalidItemsInCart ?
                    <div style={{ flex: 1, justifyContent: "center" }} >
                        <p className="cart-title">Unavailable Items</p>
                        <div>
                            <p className="cart-subtitle">Your cart contains items that are no longer available</p>
                            <p className="cart-subtitle">Please remove these items to continue</p>
                        </div>
                        {
                            invalidItemsInCart.length === 0 ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                                    <p style={{ textAlign: 'center' }}>No unavailable items</p>
                                </div>
                                :
                                <div style={{ paddingTop: 15, overflowY:"scroll" }} >
                                    {
                                        invalidItemsInCart.map((cartItem, index) => (
                                            <div key={index} className="cart-item">
                                                <img src={cartItem.product.tblProductImages ? cartItem.product.tblProductImages[0].image : null} alt={cartItem.product.productName} className="product-image" />
                                                {cartItem.product.oRate === null ?
                                                    <div className="offer-wrapper">
                                                        {cartItem.product.sRate < (cartItem.product.mrp === null ? cartItem.product.sRate : cartItem.product.mrp) &&
                                                            <p className="offer-tag">{cartItem.product.savedPercentage}%</p>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="offer-wrapper">
                                                        {cartItem.product.oRate < (cartItem.product.mrp === null ? cartItem.product.sRate : cartItem.product.mrp) &&
                                                            <p className="offer-tag">{cartItem.product.savedPercentage}%</p>
                                                        }
                                                    </div>
                                                }
                                                <div className="product-details">
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ flex: 2 }}>
                                                            <p className="product-brand">{cartItem.product.brand?.brandName}</p>
                                                            <p className="product-name">{cartItem.product?.productName}</p>
                                                            <p className="product-regional">{cartItem.product.regionalLanguage ?? ""}</p>
                                                        </div>
                                                        <div className="remove-button-wrapper">
                                                            <button className="remove-button" onClick={() => removeItem(cartItem.productId, cartItem)}>
                                                                <img src={Trash} alt="Remove" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="price-wrapper">
                                                        <div>
                                                            {cartItem.product.oRate === null ?
                                                                <div>
                                                                    {cartItem.product.mrp !== null &&
                                                                        <p className="strikethrough-price">{currencySymbol} {cartItem.product.mrp.toFixed(decimalPlaces)}</p>
                                                                    }
                                                                    <p className="discounted-price">{currencySymbol} {cartItem.product.sRate.toFixed(decimalPlaces)}</p>
                                                                </div>
                                                                :
                                                                <div>
                                                                    {cartItem.product.mrp !== null &&
                                                                        <p className="strikethrough-price">{currencySymbol} {cartItem.product.mrp.toFixed(decimalPlaces)}</p>
                                                                    }
                                                                    <p className="discounted-price">{currencySymbol} {cartItem.product.oRate.toFixed(decimalPlaces)}</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                           
                            <div className="navigation-button" onClick={() => toggleAndNavigate('/')}>
                                {/* <img src={TabHome} alt="Home" style={{ width: 25, height: 25, marginRight: 5 }} /> */}
                                <p>Continue shopping</p>
                            </div>
                            <div className="navigation-button" onClick={() => removeAllInvalidItems()}>
                                <p>Remove All & Continue</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SpinnerLoader animation="border" role="status" />
                    </div>
            }
        </div>
    )
}