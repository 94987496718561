
import ShoppinCart from '../assets/svg/bottom tab bar/shopping-cart.svg'
import '../styles/BottomNavigator.css'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Badge from '@mui/material/Badge';

const BottomNavigator = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [badgeCount, setbadgeCount] = useState(0)
    const [isbtnDisabled, setisbtnDisabled] = useState(false)
    useEffect(() => {
        getCartCount()
        window.addEventListener('productQtyChange', getCartCount)
        window.addEventListener('catalogueShare', catalogueShareevent)
        return () => {
            window.removeEventListener('catalogueShared', catalogueShareevent)
            window.removeEventListener('productQtyChange', getCartCount)
        }
    }, [])

    //Cataloguesuccess
    const catalogueShareevent = () => {
        setisbtnDisabled(false)
    }

    // get cart count
    const getCartCount = () => {
        const cart = localStorage.getItem('cartItems');
        if (!cart) {
            setbadgeCount(0)
            setisbtnDisabled(true)
        } else {
            const cartItems = JSON.parse(cart);
            setbadgeCount(cartItems.customerCartItems.length)
            setisbtnDisabled(false)
        }
    }

    function shareCatalogueEvent() {
        setisbtnDisabled(true)
        const event = new CustomEvent('sharecatalogue');
        window.dispatchEvent(event)
    }

    return (
        <div className='BottomNav'>
            <div className='BottomIconBtn' onClick={() => navigate('/')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.223" height="21.225" viewBox="0 0 19.223 21.225" stroke={location.pathname === "/" ? "#1d4e4b" : '#000'}>
                    <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3.9 -2.4)">
                        <path id="Path_114" data-name="Path 114" d="M4.5,10.009,13.511,3l9.011,7.009V21.023a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2Z" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                        <path id="Path_115" data-name="Path 115" d="M13.5,28.013V18h6.008V28.013" transform="translate(-2.992 -4.987)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                    </g>
                </svg>
            </div>
            <div className='BottomIconBtn' onClick={() => navigate('/Categories')}>
                <svg id="Category_" data-name="Category " xmlns="http://www.w3.org/2000/svg" width="20.336" height="20.292" viewBox="0 0 20.336 20.292" fill={location.pathname === "/Categories" ? "#1d4e4b" : '#000'}>
                    <path id="Path_3" data-name="Path 3" d="M139.65,24.264c.03-.639.041-1.236.091-1.83a2.61,2.61,0,0,1,.87-1.931,3.1,3.1,0,0,1,1.419-.6,11.52,11.52,0,0,1,3.238-.113,7.637,7.637,0,0,1,1.43.264,2.22,2.22,0,0,1,1.655,1.851,7.54,7.54,0,0,1,.128,1.081,17.789,17.789,0,0,1-.062,3.149,2.386,2.386,0,0,1-2.025,2.22,10.7,10.7,0,0,1-4.711-.028,2.4,2.4,0,0,1-1.938-2.277C139.687,25.442,139.678,24.831,139.65,24.264Zm4.318-2.988c-.487.037-1.1.061-1.7.135a.99.99,0,0,0-.933.877,2.22,2.22,0,0,0-.07.48c0,1.025,0,2.051.037,3.076.021.605.247.833.836.991.037.01.076.018.114.026a10.177,10.177,0,0,0,2.619.122,9.418,9.418,0,0,0,1.25-.175.844.844,0,0,0,.725-.762,2.236,2.236,0,0,0,.048-.288c0-1.051.031-2.1-.005-3.153a1.147,1.147,0,0,0-1.223-1.239C145.143,21.322,144.617,21.309,143.968,21.276Z" transform="translate(-139.65 -19.726)" />
                    <path id="Path_4" data-name="Path 4" d="M441.843,24.491c-.056.7-.072,1.4-.175,2.091a2.39,2.39,0,0,1-2.047,2.063,12,12,0,0,1-3.2.172,5.944,5.944,0,0,1-1.682-.286,2.353,2.353,0,0,1-1.685-2.084,15.912,15.912,0,0,1,.034-4.152,2.337,2.337,0,0,1,1.857-2.035,10.428,10.428,0,0,1,2.859-.247,8.074,8.074,0,0,1,1.983.244,2.4,2.4,0,0,1,1.919,2.286c.029.323.06.646.073.97s0,.649,0,.973Zm-1.583-.063c-.037-.593-.059-1.19-.115-1.784a.942.942,0,0,0-.606-.859,2.357,2.357,0,0,0-.6-.148,17.691,17.691,0,0,0-3.131,0c-.78.067-1.142.377-1.217,1.155a16.726,16.726,0,0,0,.02,3.48.856.856,0,0,0,.725.807,9.134,9.134,0,0,0,1.269.177,9.706,9.706,0,0,0,2.751-.157.911.911,0,0,0,.8-.876C440.2,25.626,440.224,25.03,440.26,24.428Z" transform="translate(-421.51 -19.998)" />
                    <path id="Path_5" data-name="Path 5" d="M442.152,317.208c-.059.708-.079,1.43-.189,2.139a2.2,2.2,0,0,1-1.6,1.861,7.061,7.061,0,0,1-1.541.293,11.538,11.538,0,0,1-3.315-.136,2.561,2.561,0,0,1-1.815-1.19,3.154,3.154,0,0,1-.386-1.415,20.419,20.419,0,0,1,.046-3.553,2.354,2.354,0,0,1,2-2.259,10.52,10.52,0,0,1,4.671,0,3.247,3.247,0,0,1,.8.305,2.158,2.158,0,0,1,1.146,1.732C442.062,315.715,442.092,316.456,442.152,317.208Zm-1.588-.128c-.033-.538-.051-1.135-.112-1.728a.974.974,0,0,0-.88-.95,12.173,12.173,0,0,0-3.7-.014.985.985,0,0,0-.957,1.007,17.275,17.275,0,0,0-.007,3.5.934.934,0,0,0,.828.915,10.2,10.2,0,0,0,3.832.009c.663-.126.831-.321.9-.987C440.53,318.271,440.532,317.7,440.564,317.08Z" transform="translate(-421.816 -301.271)" />
                    <path id="Path_6" data-name="Path 6" d="M139.64,317.788c.03-.647.038-1.238.093-1.824a4.613,4.613,0,0,1,.219-1.022,2.163,2.163,0,0,1,1.482-1.377,8.557,8.557,0,0,1,2.77-.328,9.322,9.322,0,0,1,2.22.234,2.38,2.38,0,0,1,1.959,2.144,16.286,16.286,0,0,1,.03,3.963,2.5,2.5,0,0,1-2.388,2.361,12.349,12.349,0,0,1-3.9,0,2.755,2.755,0,0,1-1.9-1.026,2.579,2.579,0,0,1-.486-1.318C139.682,318.977,139.67,318.353,139.64,317.788Zm1.569-.14c.026.577.039,1.142.081,1.7.044.6.257.878.844.978a14.038,14.038,0,0,0,1.856.172,7.737,7.737,0,0,0,2.052-.166.927.927,0,0,0,.808-.849,2.153,2.153,0,0,0,.036-.231c0-1.051.028-2.1-.005-3.153a1.065,1.065,0,0,0-1.016-1.2,12.464,12.464,0,0,0-3.591,0,1.036,1.036,0,0,0-.985,1.093C141.246,316.557,141.234,317.109,141.209,317.649Z" transform="translate(-139.64 -301.801)" />
                </svg>
            </div>
            {
                location.pathname === '/Cart' ?
                    <div className='BottomBtn' onClick={() => navigate('/Sharecatalogue')} aria-disabled={isbtnDisabled} style={{ backgroundColor: isbtnDisabled ? 'grey' : '#1d4e4b', pointerEvents: isbtnDisabled ? 'none' : 'auto' }}>
                        <div style={{ display: 'flex', flex: 0.4, paddingInline: 15 }} >
                            <Badge badgeContent={badgeCount} color="primary">
                                <img src={ShoppinCart} width={20} height={20} alt='' />
                            </Badge>
                        </div>
                        <p style={{ textAlign: 'center', margin: 0, color: 'white' }}>Place Order</p>

                    </div>
                    :
                    <div className='BottomBtn' onClick={() => location.pathname === '/Sharecatalogue' ? shareCatalogueEvent() : navigate('/Cart')} aria-disabled={isbtnDisabled} style={{ backgroundColor: isbtnDisabled ? 'grey' : '#1d4e4b', pointerEvents: isbtnDisabled ? 'none' : 'auto' }}>
                        <div style={{ display: 'flex', flex: 0.4, paddingInline: 15 }} >
                            <Badge badgeContent={badgeCount} color="primary">
                                <img src={ShoppinCart} width={20} height={20} stroke='#fff' alt='' />
                            </Badge>

                        </div>
                        <p style={{ textAlign: 'center', margin: 0, color: 'white' }}>Proceed</p>

                    </div>
            }

        </div>
    )
}

export default BottomNavigator