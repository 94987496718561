import * as language from '../constants/languages'

// get offers home banner
export const getOffersBanner = (compnyId, branchId, token) => {
	const fetchUrl = language.domainUrl + language.offersBannerUrl;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId,
			'Authorization': 'Bearer ' + token,
		},
	}).then((response) => response.json());
}


// get single offer
export const getOfferProducts = (compnyId, id, Page, Size, branchId, token) => {
	const fetchUrl = language.domainUrl + language.offerProductsUrl + '?Id=' + id + '&PageNumber=' + Page + '&PageSize=' + Size;
	// console.log(this.fetchUrl, 'this.fetchUrl');
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId,
			'Authorization': 'Bearer ' + token,
		},
	}).then((response) => response.json());
}

// special category
export const getSpecialCategory = (compnyId, branchId) => {
	const fetchUrl = language.domainUrl + language.getSpecialCategory;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId
		},
	}).then((response) => response.json());
}

//fetch category in home
export const getCategoryHome = (compnyId, branchId) => {
	const fetchUrl = language.domainUrl + language.getCategoryHomeExtUrl;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId
		},
	}).then((response) => response.json());
}

// get the branches
export const getCompanyInfo = (compnyId) => {
	const fetchUrl = language.domainUrl + language.companyInfoUrl;
	// console.log(this.fetchUrl, 'this.fetchUrl');
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
		},
	}).then((response) => response.json());
}

// get the branches
export const getBranches = (compnyId) => {
	const fetchUrl = language.domainUrl + language.branchUrl;
	// console.log(this.fetchUrl, 'this.fetchUrl');
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
		},
	}).then((response) => response.json());
}

// get support info
export const getSupportData = (compnyId) => {
	const fetchUrl = language.domainUrl + language.getSupportUrl;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId
		},
	}).then((response) => response.json());
}

// fetch the category data
export const getCategory = (compnyId, branchId) => {
	const fetchUrl = language.domainUrl + language.getCategories;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId
		},
	}).then((response) => response.json());
}

// get search products
export const getSearchProducts = (compnyId, searchName, category, brand, rating, lowRange, highRange, branchId, token, page,timeSlotIdNo) => {

	const fetchUrl = language.domainUrl + language.getCategoryPaginatedProductsNew;
	// console.log(this.fetchUrl, 'getserachprdtcs')
	var details = {
		Name: searchName,
		CategoryId: category,
		BrandId: brand,
		Rating: rating,
		PriceFrom: lowRange,
		PriceTo: highRange,
		PageNumber: page,
		PageSize: 30
	}
	// console.log(details, 'getdetailsprdtcs')
	return fetch(fetchUrl, {
		method: 'POST',
		headers: {
			'CompanyId': compnyId,
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			BranchId: branchId,
			
		},
		body: JSON.stringify(details)
	}).then((response) => response.json());
}

// product details api fectch
export const getProductDetails = (compnyId, product_id, branchId, token) => {
	const fetchUrl = language.domainUrl + language.getProductDetails + product_id;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
			'Authorization': 'Bearer ' + token,
			'BranchId': branchId
		},
	}).then((response) => response.json());
}

// order summary
export const getOrderSummary = (compnyId, branchId, token,items) => {
	const fetchUrl = language.domainUrl + language.orderSummaryNewUrl
	console.log(items,"api")
	return fetch(fetchUrl, {
		method: 'POST',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId,
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'

		},
		body: JSON.stringify(items)
	}).then((response) =>
		response.json()
		// console.log(response)
	);

}

// get country
export const getCountries = (compnyId) => {
	const fetchUrl = language.domainUrl + language.countryUrl;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
			'Content-Security-Policy': 'upgrade-insecure-requests'
		},
	}).then((response) => response.json());
}

// post local items 

export const postLocalItems = (compnyId, branchId, items, token) => {
	const fetchUrl = language.domainUrl + language.postLocalItemsUrl
	return fetch(fetchUrl, {
		method: 'POST',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId,
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(items)
	}).then((response) => response.json());
}

export const recieveToken = (compnyId, mobileno, password) => {
	const fetchLoginUrl = language.domainUrl +
		language.loginUrl
	let data = language.loginContentUrl + mobileno +
		language.registerpassword + password +
		language.companyidUrl + compnyId

	// console.log(data, 'data')
	// console.log(fetchLoginUrl, 'fetchLoginUrl')

	return fetch(fetchLoginUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: data
	}).then((response) => response.json());
}

// add new address
export const addNewAddress = (compnyId, address, token) => {
	const fetchLoginUrl = language.domainUrl + language.addNewAddress
	return fetch(fetchLoginUrl, {
		method: 'POST',
		headers: {
			'CompanyId': compnyId,
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(address)
	}).then((response) => response.json());
}


// place order
export const placeOrder = (compnyId, orderDetails, branchId, token) => {
	const fetchUrl = language.domainUrl + language.placeOrderUrlNew
	return fetch(fetchUrl, {
		method: 'POST',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId,
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(orderDetails)
	}).then((response) => response.json());

}


export const existingUser = (mobileno) => {
	const fetchUrl = language.domainUrl + language.existingUserDataUrl + mobileno
	return fetch(fetchUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'CompanyId': language.companyidValue
		}
	}).then((response) => response.json());
}


//Catalogue api
// user registration
export const addNonExistingUser = (compnyId, name, mobileno, email, password, otp) => {
	const fetchUrl = language.domainUrl +
		language.registerAPI + name +
		language.registeMobile + mobileno +
		language.registeremail + email +
		language.registerpassword + password
		;
	return fetch(fetchUrl, {
		method: 'POST',
		headers: {
			'CompanyId': compnyId
		},
	}).then((response) => response.json());
}

export const getAboutData = (compnyId) => {
	const fetchUrl = language.domainUrl + language.getAboutUrl;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId
		},
	}).then((response) => response.json());
}

// terms and condition
export const getTermsData = (compnyId) => {
	const fetchUrl = language.domainUrl + language.getTermsUrl;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId
		},
	}).then((response) => response.json());
}


// get offers home banner
export const getOffers = (compnyId, branchId, token) => {
	const fetchUrl = language.domainUrl + language.offersUrl;
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': compnyId,
			'BranchId': branchId,
			'Authorization': 'Bearer ' + token,
		},
	}).then((response) => response.json());
}

export const sendWhatsapp = (phonenumber, message, instanceid, accesstoken) => {
	const fetchUrl = `https://sapiensdigitals.in/api/send.php?number=${phonenumber}&type=text&message=${message}&instance_id=${instanceid}&access_token=${accesstoken}`
	return fetch(fetchUrl, {
		method: 'POST',
	}
	).then((response) => response.json());
}

export const createRazorPayOrder = (orderId, amount, token) => {
	const fetchUrl = language.domainUrl + language.razorpayCreateOrder + "?amount=" + amount + "&receiptId=" + orderId
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'CompanyId': language.companyidValue,
			'Authorization': 'Bearer ' + token,
		},
	}).then((response) => response.json())
}

export const getOrderWithOrderId = (compnyId, orderId) => {
	const fetchUrl = language.domainUrl + language.getOrderDetailsWithIdUrl + orderId
	return fetch(fetchUrl, {
		method: 'GET',
		headers: {
			'companyId': compnyId,
		},
	}).then((response) => response.json());
}

